:root {
  background-color: #fff;
}
body{
  margin: 0 !important;
  padding: 0 !important;
}

.makeStyles-button-56{
  color:#ffffff;
}


.MuiTableCell-root{
  padding: 6px;
}
.makeStyles-main-71 .MuiTableCell-root {
  padding: 6px;
}
.MuiTableCell-alignCenter {
  padding: 6px;
}
.MuiTableCell-root {
  padding: 6px;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #fff !important;
}
.MuiBox-root-45 {
  background-color: #313C52 !important;
  margin-top: 17px;
}
.makeStyles-buttonLeaf-51.depth-0 .makeStyles-title-53 {
  /* color: #FCC200; */
}
.MuiBox-root-110 {
  background-color: #313C52 !important;
}
.MuiTableCell-head {
  background-color: #FCC200 !important;
}

.makeStyles-headCell-72{
  background-color: #FCC200 !important;
}
.MuiBox-root-379{
  background-color: #313C52 !important;
}
.makeStyles-active-123 {
  color: #FCC200 !important;
}
.MuiIconButton-root {
  color: #FCC200 !important;
}

.MuiPickersDatePickerRoot-toolbar {
  color: #FCC200 !important;
}
.MuiPickersToolbar-toolbar {
  color: #FCC200 !important;
}

.MuiPickersDay-daySelected{
  color: #ffffff !important;
}


.MuiButton-textPrimary{
  color: #FCC200 !important;
}
.MuiPickersToolbar-toolbar{
  background-color:#FCC200 !important ;
}

.MuiPickersDay-daySelected{
  background-color:#FCC200 !important ;
}
.MuiButton-textPrimary{
  color:#FCC200 !important ;
}
.makeStyles-forminputDate-172 .MuiFormControl-marginDense{
  margin-top: 0px !important;
  width: 100%;
}

.MuiBox-root-290{
  background-color:#313C52 !important ;
}


.makeStyles-TextBox-120{
  background-color: transparent !important;
}
.MuiOutlinedInput-inputAdornedEnd{
  color:#ffffff !important;
}
.makeStyles-TextBox-120{
  background-color: none !important;
}

.makeStyles-TextBox-120{
  background-color: none !important;
}

.makeStyles-logoutButton-30{
  background-color: #FCC200;
}

.makeStyles-btn1-418{
  border-radius: 15px !important;
}

.makeStyles-TextBox-173 {
  color:"#ffffff !important"
}
.MuiMenu-list {
  background-color:#313C52 !important;
  border:1px solid #fff
}
.MuiSelect-outlined.MuiSelect-outlined {
  color: #fff;
}
.makeStyles-colorbox-74 h2 {
  color:"#fff"
}
button.MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day.MuiPickersDay-dayDisabled {
  color:#e6cc6d !important
}

input:-webkit-autofill {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: #848484 !important;
}